import React from 'react'
import SellerieAutoContenu from '../components/contenu/SellerieAutoContenu';
import SellerieAutoCaroussel from '../components/caroussel/SellerieAutoCaroussel';

function SellerieAuto() {
    return (
        <>
        <SellerieAutoCaroussel/>
        <SellerieAutoContenu/>
        </>
    )
}

export default SellerieAuto