import React from 'react'
import SellerieMotoContenu from '../components/contenu/SellerieMotoContenu';
import SellerieMotoCaroussel from '../components/caroussel/SellerieMotoCaroussel';

function SellerieMoto() {
    return (
        <>
            <SellerieMotoCaroussel />
            <SellerieMotoContenu />
        </>
    )
}

export default SellerieMoto