import React from 'react'
import SiegesContenu from '../components/contenu/SiegesContenu';
import SiegesCaroussel from '../components/caroussel/SiegesCaroussel'

function Sieges() {
    return (
    <>
        <SiegesCaroussel />
        <SiegesContenu />
    </>
    )
}

export default Sieges