import React from 'react'
import DecorationContenu from '../components/contenu/DecorationContenu';
import DecorationCaroussel from '../components/caroussel/DecorationCaroussel'

function Decoration() {
    return (
        <>
            <DecorationCaroussel />
            <DecorationContenu />
        </>
    )
}

export default Decoration


