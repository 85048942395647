import React from 'react'
import BanquettesContenu from '../components/contenu/BanquettesContenu'
import BanquettesCaroussel from '../components/caroussel/BanquettesCaroussel'


function Banquettes() {
    return (
        <>
            <BanquettesCaroussel />
            <BanquettesContenu />
        </>
    )
}

export default Banquettes