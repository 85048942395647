import React from 'react'
import './Caroussel.css'
import car1 from ".//../../assets/img/pages/sellerieauto/sell2.webp"


function SellerieAutoCaroussel() {
    return (
        <div class="container-caroussel-siege">
            <div class="non-caroussel">
                <img src={car1} fetchpriority="high" alt="Caroussel 1" />
            </div>
        </div>
    )
}

export default SellerieAutoCaroussel